.contact_sec2 {
  margin-top: 10px;
  padding-bottom: 5em;
}

.contact_sec2 .text_container {
  text-align: center;
}
.card-container {
  display: flex;
  justify-content: space-evenly;
}

.icon_img{
  height: 4.5rem;
  width: 20%;
  text-align: center;
  color: red;
  /* border: 1px solid rgb(82, 80, 80); */
  /* border-radius: 50%; */
}

.card2 {
  width: 280px;
  height: 200px;
  padding-top: 10px;
  /* border: 1px solid rgb(240, 15, 15); */
   
}
.card2:hover {
  background-image: url("../../../assets/small\ img.png");
}
.card2:hover .icon_img
{
  color: white; 
  /* border: 1px solid rgb(255, 254, 254); */
}
.card2:hover .desc {
  color: white;
}
.card2:hover .heading{
  color: white;
}
.box_container {
  text-align: center;
}

.desc {
  margin-top: 10px;
  width: 100%;
}
.flex2{
  display: flex;
  justify-content: space-evenly;
}
.form {
  max-width: 100rem;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  font-size: 17px;
}

.form label {
  display: block;
  margin-bottom: 0.5rem;
  height: 10px;
}

.form input[type="text"],
.form input[type="email"],
.form textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.form textarea {
  resize: vertical;
}
.formdiv{
  text-align:center
}

.error-message {
  background-color: #dc3545;
  color: #fff;
  padding: 0.75rem;
  border-radius: 0.25rem;
  margin-top: 1rem;
}

.success-message {
  background-color: #28a745;
}


@media (max-width: 768px) {
  .card-container {
    flex-direction: column; 
    align-items: center; 
  }
  .text_container .heading{
    font-size: 1.5rem;
  }.flex2{
    display: block;
  }
  .about-image{
    height: 35rem;
    width: 35rem;
  }

}