.footer {
  background-color: #f0f0f0;
  padding: 30px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-column {
  width: 100%;
  margin-bottom: 10px;
}

.footer-heading {
  font-size: 18px;
  color: #333;
  /* margin-bottom: 15px; */
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-link {
  color: #555;
  text-decoration: none;
  margin-bottom: 5px;
  display: block;
}

.footer-link:hover {
  color: #f70404;
}

.footer-contact {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.footer-icon {
  color: #555;
  margin-right: 10px;
  height: 9rem;
}


.footer-text {
  color: #555;
  width:100%;
  margin: 0;
}

.footer-social {
  display: flex;
}

.footer-social-icon {
  font-size: 24px;
  color:#555; 
  margin-right: 10px;
}
.footer-social-icon:hover {
  color: #ff0000;
}
.add{
  width: 100%;
}
.addph{
  display: block;
}

@media screen and (min-width: 768px) {
  .footer-column {
    width: 31%; /* Adjusted width for three columns */
  }
}
