.about-us {
  padding: 40px 0;
  background-color: #ffff;
  
}

.about-content {
  padding-right: 20px; /* Adjust as needed */
}

.section-title {
  color: #333;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
}
h3,h2{
  cursor: pointer;
}

p {
  width: 50%;
  cursor: pointer;
}

.section-desc {
  color: #666;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  cursor: pointer;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-image {
  height: 35rem;
}

.flex {
  display: flex;
  justify-content: space-evenly;
}

.image-block {
  display: flex;
  justify-content: space-evenly;
}

.imag1 {
  position: relative;
}

.caption {
  position: absolute;
  bottom: 30px;
  left: 10px;
  align-items: center;
  margin: 0;
  padding: 5px;
  font-weight: 600;
  font-size: 2.5rem; /* Optional: Add padding for better visibility */
  background-color: rgba(0, 0, 0, 0.5); /* Optional: Add background color for better readability */
  color: #fff; /* Optional: Add text color for better readability */
}

.discover {
  color: #fff;
  font-size: 1rem;
}
.discover:hover {
  color: red; 
}

.img {
  height: 40rem;
  width: 35rem;
}

@media screen and (max-width: 767px) {
  .flex,
  .image-block {
    display: block; /* Change to block layout for mobile */
  }

  p,
  .about-image {
    width: auto; /* Reset width for paragraph and image */
    margin-bottom: 20px; /* Add some spacing between paragraph and image */
  }

  .about-content {
    padding-right: 0; /* Adjust padding for mobile */
  }

  .about-image {
    height: 30rem;
    text-align: center; /* Adjust height for mobile */
  }

  .section-title {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
    color: #666;
   
  }
  .about-content h3{
    width: 100%;
  }

  .button {
    margin: 0;
    text-align: center;
    font-size: 12px;
    height: 5rem;
    width: 13.9rem;
  }

  .ourbusiness {
    text-align: center;
  }

  .img {
    height: 35rem;
    width: 30rem;
  }
  .caption {
    left: 30px;
    font-size: 2rem;
    bottom: 15px;

  }
}

.button {
  background-color: #fff;
  border: red 2px solid;
  color: rgb(12, 12, 12);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s, transform 0.3s; /* Add transitions for color, background-color, and transform */
}

/* CSS for hover effect */
.button:hover {
  background-color: rgb(247, 68, 68);
  color: #fff; /* Change text color on hover */
  transform: translateX(5px); /* Slide button 5px to the right on hover */
}

.button:hover span {
  color: #fff;
  /* Text color on hover */
}
