.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px; /* Adjust the height as needed */
  font-size: 1.5rem;
}

.map-container {
  overflow: hidden;
  border-radius: 8px;
  padding-top: 10px; /* Adjust the border radius as needed */
}

.map-frame {
  width: 100%; /* Adjust to take full width on small screens */
  height: 250px; /* Adjust the default height for smaller screens */
}
.two-div {
  display: flex;
  justify-content: space-evenly;
  bottom: 10px;
  text-align: center;

}
.icon{
  height: 4rem;
  width: 20%;
  text-align: center;
  color: red;
}
.card2:hover .icon
{
  color: white; 
 /* Change color of icon and description text to white on hover */
}
.add1 p{
 width:100%;
 text-align: center;
}
@media screen and (max-width: 600px) {
  .two-div {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    align-items: center; /* Center elements horizontally */
  }
  
  .add1 {
    width: 100%; /* Set width to 100% for full width on smaller screens */
    margin-bottom: 10px;
    text-align: center; /* Add margin between elements */
  }
}
/* Media query for larger screens */
@media screen and (min-width: 640px) {
  .map-frame {
    height: 350px; /* Adjust the height for small screens */
  }
  
}

/* Media query for medium screens */
@media screen and (min-width: 768px) {
  .map-frame {
    height: 450px; /* Adjust the height for medium screens */
  }
}
@media screen and (max-width: 767px) {
  .two-div {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items vertically */
  }
}