.slider{
  padding-top: 10px;
  padding-bottom: 10px;
}
p{
  width: 40%;
}
h3{
  width: 40%;
}
h2{
  color: rgb(145, 144, 144);
}

.button {
  background-color: #494b49; /* Original background color */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
/* CSS for hover effect */
.button:hover {
  background-color: #0e3227; /* Background color on hover */
}

.button:hover span {
  color: #fff; /* Text color on hover */
}
span{
  font-size: 2rem;
  font-family:'Times New Roman', Times, serif;
}
.about{
  display: flex;
  justify-content: space-evenly;
}
.about img {
  height: 40rem;
  margin-left: auto; /* Auto margin to center the image horizontally */
  margin-right: auto;
  margin-top: auto; /* Auto margin to center the image horizontally */
}
@media only screen and (min-width:667px) {
  /* Apply styles for screens larger than 768px */
  .about {
    flex-direction: row; /* Revert to row layout for larger screens */
  }

  p,
  h3 {
    width: 100%; /* Reset width for larger screens */
  }
}

@media only screen and (min-width: 1024px) {
  /* Apply styles for screens larger than 1024px */
  .about img {
    height: 40rem; /* Adjust image height for larger screens */
  }
}
