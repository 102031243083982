.admin-card {
    margin: 10%;
    box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
    padding: 5%;
    border-radius: 20px;
}

.input-admin{
    width: 100%;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #eee;
    margin-bottom: 10px;
}

.ml-3{
    margin-left: 30px;
}
.addbutton{
    border: 1px solid red;
    width: 15%;
    background-color: white;
    height: 30px;
}
.addbutton:hover{
    color: #eee;
    background-color: red;
    
}
@media screen and (max-width: 767px) {
    .addbutton {
    width: 40%; 
    }
}