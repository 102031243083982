.card {
    /* border: 1px solid red; */
    padding: 30px;
    margin-left: 3%;
    margin-top: 20px;
    min-height: 200px;
    width: 30%;
    box-shadow: 0px 10px 30px 0px rgb(50 50 50 / 16%);
    border-radius: 7px;
}

.title {
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
}

.desc {
    text-align: center;
}

.flex {
    display: flex;
    flex-wrap: wrap;
    
}

/* Container to center the search input */
.search-container {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  /* Styles for the search input */
  .filter {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 200px; /* Adjust the width as needed */
  }
  
  .filter:focus {
    outline: none;
    border-color: rgb(255, 30, 30); /* Change the border color when focused */
  }
  @media screen and (max-width: 768px) {
    .card {
        width: 80%;
        display: block; /* Adjust the width for smaller screens */
    }
}