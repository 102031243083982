.fixed {
  width: 100%;
  position: fixed;
  top: 0;
  transition: all 0.5s;
  z-index: 9;
}

.header {
  width: 100%;
  height: 7.75rem;
  margin: 0 auto;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* background-color: #0e3227; */
  background-size: cover;
  background-position: center;
  
}

.header .logo_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-width: 20rem;
  border-top-right-radius: 0.313em;
  border-bottom-right-radius: 0.313em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .logo_wrapper .raymond_logo {
  width: 80%;
}

@media (max-width: 768px) {
  .header .logo_wrapper {
    top: 0px;
    left: 0px;
    height: 100%;
    width: 50%;
    max-width: 15rem;
    border-top-right-radius: 0.313em;
    border-bottom-right-radius: 0.313em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

nav {
  width: 75%;
  display: flex;
  justify-content: space-between;
}

nav ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
}

nav ul .logo-mobile {
  display: none;
}

nav ul li {
  margin: 0 25px;
  position: relative;
}

nav ul li:hover .submenu-content {
  display: block;
}

nav ul li a {
  color: #302f2f;
  font-weight: 500;
}

nav ul li a:hover {
  color: #000;
}

/* .menu li .submenu-content {
  display: none;
  position: absolute;
  top: 20px; 
  background-color: #fff;*/
  /* background-color: #0e3227; */
  /* min-width: 200px;
  z-index: 10; 
} */

/* .submenu-content li {
  display: block;
}

.submenu-content li a {
  padding: 10px 15px;
  color: #474747;
  text-decoration: none;
}

.submenu-content li:hover {
  background-color: #fff;
}  */

.menu-icon {
  cursor: pointer;
  display: none;
  color: #020202;
  right: 0px;
}

@media screen and (max-width: 800px) {
  nav {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100vh;
    background-color: rgb(228, 228, 228);
    padding: 1rem;
    z-index: 999;
    transform: translateX(-200%);
    transition: all 0.3s;
  }

  .nav-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    transform: translateX();
    transition: all 0.3s;
  }

  .show-nav-wrapper {
    transform: translateX(100%);
  }

  nav ul {
    display: block;
    margin-top: 50px;
  }

  nav ul .logo-mobile {
    display: block;
  }

  nav ul li {
    margin-top: 10px;
    padding: 5px 0;
  }

  .show-nav {
    transform: translateX(0);
  }

  .hide-nav {
    transform: translateX(-200%);
  }

  .menu-icon {
    display: flex;
    > * {
      margin-left: 2rem;
    }
  }
}

.active {
  position: relative;
  color: red;
  text-decoration: none;
}

.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 2px;
}

/* Additional CSS for mobile responsiveness */
@media screen and (max-width: 800px) {
  .header {
    height: auto;
    padding: 2rem;
  }

  .logo_wrapper {
    max-width: 100%;
    text-align: center;
  }

  .raymond_logo {
    width: 70%;
  }

  nav {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    padding: 1rem;
    z-index: 999;
    display: none;
  }

  .show-nav {
    display: block;
  }

  ul.menu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .menu li {
    margin: 1rem 0;
    position: relative;
  }

  /* .menu li:hover .submenu-content {
    display: block;
  }

  .menu .submenu-content {
    position: absolute;
    top: 100%; /* Adjusted */
    /* left: 0;
    display: none;
    z-index: 10; 
  }*/

  /* .submenu-content li {
    display: block;
  }

  .submenu-content li a {
    padding: 10px 15px;
    color: black;
    text-decoration: none;
  }

  .submenu-content li:hover {
    background-color: #0e3227;
  }  */

  .menu-icon {
    display: flex;
    align-items: center;
  }
}
