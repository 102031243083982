.copy {
    background-color:#fff;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
    p {
      color: #000000;
    }
  }
  
  @media screen and (max-width: 767px) {
    .copy {
      font-size: 1rem; /* Adjust font size for mobile devices */
      padding: 0 10px; /* Add padding for better readability */
      text-align: center; /* Center align text */
    }
  }