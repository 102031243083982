.slider {
  width: 100%;
  height: 85vh;
  position: relative;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateX(-50%);
  transition: all 0.5s ease;
}

@media screen and (min-width: 600px) {
  .slide img {
    width: 100%;
    height: 100%;
  }
}

.slide img {
  height: 100%;
  filter: brightness(50%);
}

.content {
  position: absolute;
  text-align: center;
  top: 23rem;
  left: 50%;
  opacity: 0;
  width: 50%;
  padding: 3rem;
  display: flex;
  justify-self: center;
  align-items: center;
  flex-direction: column;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.4);
  animation: slide-up 1s ease 0.5s;
  animation-fill-mode: forwards;
}
// }  visibility: hidden;
  h2 {
    font-size: 4.5rem;
  }


@keyframes slide-up {
  0% {
    visibility: visible;
    top: 23rem;
  }
  100% {
    visibility: visible;
    top: 17rem;
  }
}

@media screen and (max-width: 600px) {
  // .content {
  //   width: 80%;
  //   top:5rem;
  // }
  .slider{
  
    width: 100%;
    height: 50vh;
  }
  .slide img {
    height: 100%;
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .content {
    width: 80%; /* Adjust width for smaller screens */
    padding: 2rem; /* Adjust padding for smaller screens */
    top: 15rem; /* Adjust top position for smaller screens */
    left: 50%;
    transform: translateX(-50%);
    justify-self: center;
  align-items: center;
  }
}

@media only screen and (max-width: 480px) {
  .content {
    width: 90%; /* Further adjust width for smaller screens */
    padding: 1.5rem; /* Further adjust padding for smaller screens */
    top: 10rem; 
    justify-self: center;
  align-items: center;/* Further adjust top position for smaller screens */
  }
}

.content > * {
  color: #fff;
  margin-bottom: 1rem;
}

.current {
  opacity: 1;
  transform: translateX(0);
}

.current .content {
  opacity: 1;
}
.arrow {
  // border: 2px solid rgb(223, 217, 214);
  // border-radius: 50%;
  background: transparent;
  color: #fff;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  opacity: 1;
}

// .arrow:hover {
//   background: #fff;
// }

.next {
  right: 1.5rem;
  color: rgb(225, 216, 212);
  opacity: 0;
}
.prev {
  left: 1.5rem;
  color: rgb(232, 224, 222);
  opacity: 0;
}

hr {
  height: 2px;
  background: #fff;
  width: 50%;
}
.sliderbutton{
  border: 1px solid red;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
.sliderbutton:hover {
  background-color: red;
  color: #fff;
}
