.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .aboutpage {
    text-align: center;
    padding-top: 10px;
  }
  .aboutpage h3{
    color: #333;
  }
  .section-desc strong {
    font-weight: bold;
  }
  .section-desc ul {
    font-size: 15px;
    text-align: center;
  }
  
  
  .section-desc li {
    margin-bottom: 10px; /* Adjust as needed for spacing between items */
  }
  .list{
    display: flex;
    justify-content: space-between;
  }
  @media screen and (max-width: 768px) {
    .aboutpage h3{
      text-align: center;
    }
    .section-desc ul {
      font-size: 1.2rem;
      text-align: center;
    }
    
    .section-desc li {
      margin-bottom: 5px;
    }
    .section-2{
      font-size: 1.3rem;
    }
  }
  @media screen and (max-width: 767px) {
    .section-desc h4 {
      font-size: 1.5rem; /* Adjust font size for mobile devices */
      // margin-top: 10px; /* Add some spacing between paragraphs */
    }
  }