.about-content3 {
    padding-top: 10px;
  }
  
  .section-title3 {
    color: rgb(61, 60, 60);
    text-align: center;
    font-size: 30px;
  }
  
  .businesslist {
    display: flex;
    justify-content: space-between;
    text-align: center;
    cursor: pointer;
  }
  
  .businesslist ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%; /* Adjust for full width on mobile */
  }
  
  .businesslist ul li {
    margin-bottom: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .business-icon {
    color: #555;
    margin-right: 10px;
  }
  
  .about-content3 h3 {
    cursor: pointer;
  }
  
  .section-title3 {
    cursor: pointer;
  }
  .para{
    text-align: center;
    width: 100%;
  }
  .suiting{
    height: 300px;
    width: 100%;
  }
  .section-title6{
    color: #2c2b2b;
    text-align: center;
  }
  .section-title6:hover{
    color: #ff0505;
    
  }
  .end{
    text-align: center;
    width: 100%;
  }
  .endbutton {
    background-color: #fff;
    border: 2px solid red;
    color: rgb(12, 12, 12);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: block;
    font-size: 12px; /* Adjust font size as needed */
    margin: 0 auto; /* This will center the button horizontally */
    cursor: pointer;
    transition: color 0.3s, background-color 0.3s, transform 0.3s;
    width: fit-content; /* Adjust width as needed */
}
  .endbutton:hover{
    background-color: #ff0505;
    color: #fff;
  }

  /* Media query for screens smaller than 768px (typical mobile devices) */
  @media (max-width: 768px) {
    .businesslist {
      display: block;
      text-align: center; /* Change to block layout for mobile */
    }
    .businesslist ul li {
        font-size: 14px;
      }
      .section-title3 {
        font-size: 1.8rem;
      }
      .head{
        font-size: 1.8rem;
        width: 100%;
        text-align: center;
      }
      .endbutton{
        text-align: center;
        font-size: 12px;
        height: 5rem;
        width: 15.5rem;
      }
  }